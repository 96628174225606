export default [
  {
    title: "Facebook",
    target: "_blank",
    href: "https://www.facebook.com/yellowstones",
    className: "fab fa-facebook"
  },
  {
    title: "Linkedin",
    target: "_blank",
    href: "https://www.linkedin.com/company/weareyellowstones",
    className: "fab fa-linkedin"
  },
  {
    title: "Twitter",
    target: "_blank",
    href: "https://twitter.com/Bobcaatsocial",
    className: "fab fa-twitter"
  },
  {
    title: "Email",
    target: undefined,
    href: "mailto:contact@yellowstones.com?subject=I%20have%20a%20question!&body=Please%20tell%20us%20what%20you%20think",
    className: "fa fa-envelope"
  },
];

export const ceciliaChannels = [

  {
    title: "Linkedin",
    target: "_blank",
    href: "https://www.linkedin.com/in/cecilia-devitt-barbecot-96448865/",
    className: "fab fa-linkedin",
  },
  {
    title: "Email",
    target: undefined,
    href: "mailto:cecilia@yellowstones.io",
    className: "fa fa-envelope"
  },
  {
    title: "Website",
    target: undefined,
    href: "https://https://freelancebycecilia.com/",
    className: "fa fa-globe-europe"
  },
];


export const thibaultChannels = [

  {
    title: "Linkedin",
    target: "_blank",
    href: "https://www.linkedin.com/in/thibault-drevon-967a1884/",
    className: "fab fa-linkedin"
  },
  {
    title: "Email",
    target: undefined,
    href: "mailto:thibault@yellowstones.io",
    className: "fa fa-envelope"
  },
  {
    title: "Website",
    target: undefined,
    href: "http://thibault.drevon.me",
    className: "fa fa-globe-europe"
  },
];