import React, {useState} from "react";
import AppBar from "./AppBar";
import {bool} from "prop-types";

const appBarContainer = ({isHome}) => {

  const [mobileMoreAnchor, setMobileMenuAnchor] = useState(null);
  const [languageMoreAnchor, setLanguageMenuAnchor] = useState(null);

  return <AppBar
    isHome={isHome}
    mobileMoreAnchorEl={mobileMoreAnchor}
    onOpenMobileMenu={e => setMobileMenuAnchor(e.currentTarget)}
    onCloseMobileMenu={() => setMobileMenuAnchor(null)}
    onOpenLanguageMenu={e => setLanguageMenuAnchor(e.currentTarget)}
    onCloseLanguageMenu={() => setLanguageMenuAnchor(null)}
    languageMoreAnchorEl={languageMoreAnchor}
  />
};

appBarContainer.propTypes = {
  isHome: bool,
};

appBarContainer.defaultProps = {
  isHome: false,
};

export default appBarContainer;
