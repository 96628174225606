import React from 'react';
import {darken, Typography, withStyles} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import jumboBack from "../../shared/images/jumbo.jpg";

const styles = theme => ({
  root: {
    position: "relative",
    width: "100vw",
    minHeight: "100vh",
    backgroundAttachment: "fixed",
    background: `${theme.palette.primary.main} url('${jumboBack}') no-repeat center`,
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    transition: "font-size 0.5s ease",
    color: theme.palette.common.white,
    fontWeight: 'bold',
    textTransform: "uppercase",
  },
  subtitle: {
    color: darken(theme.palette.common.white, 0.1),
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
    textTransform: "uppercase",
  },
  text: {
    maxWidth: 600,
    margin: "auto",
    padding: theme.spacing(2),
  },
});

const jumbo = ({classes}) => (
  <section role="banner" id="home">
    <div className={classes.root}>
      <div className={classes.text}>
        <Typography align="center" className={classes.title} component="h1" variant="h2">
          <Translate id="Jumbo.title"/>
        </Typography>
        <Typography align="center" variant="h5" className={classes.subtitle} color="textSecondary">
          <Translate id="Jumbo.subtitle"/>
        </Typography>
      </div>
    </div>
  </section>
);

export default withStyles(styles)(jumbo);