import React, {useState} from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import bobcaatImage from "../../shared/images/bobcaat-2.png";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import grey from "@material-ui/core/colors/grey";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import classnames from "classnames";
import {Translate} from "react-localize-redux";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    paddingTop: "4rem",
    paddingBottom: "4rem",
    backgroundColor: grey[200]
  },
  container: {
    margin: "auto",
    maxWidth: 1400,
  },
  imageContainer: {
    minHeight: 400,
    width: "100%",
    height: "100%",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    }
  },
  text: {
    textAlign: "center",
    margin: theme.spacing(4),
    maxWidth: 600,

    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    }
  },
  textWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    }
  },
  sectionTitle: {
    maxWidth: 600,
    margin: "auto",
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    width: "100%",
    height: "100%",
    background: `url('${bobcaatImage}') no-repeat center center`,
    backgroundSize: "contain",
  },
  expansionPanel: {
    textAlign: "left",
    backgroundColor: grey[200],
    "&:before": {
      content: "none",
    }
  },
  expansionPanelSummary: {
    backgroundColor: grey[200],
  },
  itemAvatar: {
    transition: "background-color 0.3s ease",
  },
  itemAvatarActive: {
    backgroundColor: theme.palette.primary.main,
  },
  discoverButton: {
    marginTop: theme.spacing(2),
  }
});


const ITEMS = [
  {
    primary: "Bobcaat.items._1.primary",
    secondary: "Bobcaat.items._1.secondary",
  },
  {
    primary: "Bobcaat.items._2.primary",
    secondary: "Bobcaat.items._2.secondary",
  },
  {
    primary: "Bobcaat.items._3.primary",
    secondary: "Bobcaat.items._3.secondary",
  },
  {
    primary: "Bobcaat.items._4.primary",
    secondary: "Bobcaat.items._4.secondary",
  },
];

const bobcaat = ({classes}) => {
  const [activePanel, setActivePanel] = useState(0);

  return (
    <section className={classes.root} id="bobcaat">
      <div className={classes.container}>
        <div className={classes.sectionTitle}>
          <Typography variant="overline" color="primary">
            <Translate id="Bobcaat.title"/>
          </Typography>
          <Typography variant="h5" paragraph>
            <Translate id="Bobcaat.subtitle"/>
          </Typography>
        </div>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className={classes.textWrapper}>
              <div className={classes.text}>
                <Typography align="left">
                  <Translate id="Bobcaat.contentStart"
                             options={{renderInnerHtml: true}}/>
                </Typography>
                {ITEMS.map((props, index) => (
                  <ExpansionPanel elevation={0}
                                  onChange={() => setActivePanel(index)}
                                  expanded={index === activePanel}
                                  className={classes.expansionPanel}
                                  key={index}>
                    <ExpansionPanelSummary
                      className={classes.expansionPanelSummary}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            className={classnames(classes.itemAvatar, index === activePanel && classes.itemAvatarActive)}>{index + 1}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primaryTypographyProps={{className: classes.bold}}
                          primary={<Translate id={props.primary}/>}/>
                      </ListItem>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography variant="body1" color="textSecondary">
                        <Translate id={props.secondary}/>
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <Button className={classes.discoverButton} color="primary" variant="contained" component="a" href="https://bobcaat.com" target="_blank">
                  <Translate id="Bobcaat.discover"/>
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.imageContainer}>
              <div className={classes.image}/>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default withStyles(styles)(bobcaat);